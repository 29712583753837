import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import richText from "../utils/richText"
import { Header2 } from "../components/typography"
import colors from "../styles/colors"
import styled from "styled-components"
import Seo from "../components/seo"

export const query = graphql`
  query Page($id: String) {
    contentfulGenericPage(id: { eq: $id }) {
      content {
        raw
      }
      title
      url
    }
  }
`

const Header = styled.div`
  color: ${colors.creamLight};

  margin: 0 auto;

  padding: 48px 24px;

  border-bottom: 1px solid ${colors.creamLight};

  text-align: center;
`

const Wrapper = styled.div`
  color: ${colors.creamLight};

  max-width: 800px;
  margin: 40px auto 80px;

  padding: 0 24px 48px;

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: ${colors.orangeDark};
  }

  > h1,
  h2,
  h3,
  h4,
  h4 {
    margin-top: 40px;
    margin-bottom: 16px;
  }
`

export default function GenericTemplate({ data }) {
  const { contentfulGenericPage: page } = data

  return (
    <Layout>
      <Seo title={page.title} />
      <Header>
        <Header2>{page.title}</Header2>
      </Header>
      <Wrapper>
        <div>{richText(page.content.raw)}</div>
      </Wrapper>
    </Layout>
  )
}
